* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  background: #000;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  font-family: Arial, sans-serif;
  display: flex;
}

img {
  max-width: 100%;
  height: auto;
}

.header, .tabs, .content {
  width: 100%;
  max-width: 720px;
  margin: 0;
  padding: 0;
}

.header {
  z-index: 1000;
  backdrop-filter: blur(8px);
  z-index: 1000;
  background: #15181b66;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 45rem;
  padding: clamp(.5rem, 2vw, 1rem) clamp(.75rem, 2vw, 1.5rem);
  display: flex;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.header-left {
  flex: 0 20%;
  align-items: center;
  gap: 10px;
  min-width: 0;
  display: flex;
}

.company-logo {
  object-fit: contain;
  cursor: pointer;
  width: auto;
  height: clamp(16px, 4vw, 32px);
}

.back-button {
  cursor: pointer;
  width: clamp(16px, 4vw, 32px);
  height: clamp(16px, 4vw, 32px);
}

.center-logo {
  text-align: center;
  flex: 0 60%;
  min-width: 0;
  margin: 0 1rem;
}

.center-logo img {
  object-fit: contain;
  width: auto;
  height: clamp(16px, 4vw, 32px);
}

.language-select {
  flex: 0 20%;
  justify-content: flex-end;
  min-width: 0;
  height: fit-content;
  margin-right: 8px;
  display: flex;
  position: relative;
}

.language-select .dropdown {
  color: #fff;
  max-width: 100%;
  display: inline-flex;
}

.language-select .dropdown-select {
  cursor: pointer;
  backdrop-filter: blur(4px);
  white-space: nowrap;
  background: #ffffff26;
  border-radius: 100px;
  justify-content: space-between;
  align-items: center;
  gap: .25rem;
  width: fit-content;
  padding: 8px 12px;
  font-size: 12px;
  display: flex;
}

.language-select .dropdown-content {
  z-index: 1;
  cursor: pointer;
  backdrop-filter: blur(4px);
  white-space: nowrap;
  background: #363333eb;
  border-radius: 12px;
  width: max-content;
  min-width: 100%;
  max-width: calc(100vw - 3rem);
  display: none;
  position: absolute;
  top: calc(100% + 10px);
  left: auto;
  right: 0;
  box-shadow: 0 8px 16px #0003;
}

.language-select .dropdown-option {
  cursor: pointer;
  color: #fff6;
  white-space: nowrap;
  text-overflow: ellipsis;
  align-items: center;
  width: 100%;
  padding: .5rem .75rem;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  overflow: hidden;
}

.language-select .dropdown-option:hover {
  background: #ffffff14;
}

.language-select .dropdown-option[data-lang="${currentLang}"] {
  color: #fff;
  font-weight: 700;
}

.language-select .dropdown.active .dropdown-content {
  padding-top: 8px;
  padding-bottom: 8px;
  display: block;
}

.language-select .dropdown.active .dropdown-arrow {
  transform: rotate(180deg);
}

#selected-lang {
  font-size: 14px;
  font-weight: 700;
}

.content-wrapper {
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 720px;
  margin-top: calc(clamp(16px, 4vw, 32px) + clamp(1rem, 4vw, 2rem) + 12px);
  display: flex;
}

.tabs {
  max-height: 80px;
  line-height: 0;
  position: relative;
}

.tab-image {
  width: 100%;
  height: auto;
  display: block;
}

.tab-button {
  cursor: pointer;
  background: none;
  border: none;
  width: 50%;
  height: 100%;
  padding: 0;
  position: absolute;
  top: 0;
}

.tab-button-left {
  left: 0;
}

.tab-button-right {
  right: 0;
}

.content {
  font-size: 0;
  line-height: 0;
  position: relative;
}

.content-image {
  width: 100%;
  height: auto;
  display: block;
}

.youtube-container {
  aspect-ratio: 16 / 9;
  transition: all .3s;
  position: absolute;
  transform: translate(-50%, -50%);
}

.action-button {
  color: #fff;
  cursor: pointer;
  background: none;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  font-size: 16px;
  line-height: 1.5;
  transition: all .3s;
  position: absolute;
}

#firstTabContent, #secondTabContent, #applyContent {
  display: none;
}

@media screen and (width <= 480px) {
  .header {
    padding: .75rem 1rem;
  }

  .center-logo {
    margin: 0 .5rem;
  }

  .dropdown-arrow {
    width: .7rem;
    height: .7rem;
  }

  .language-select .dropdown-option {
    padding: .5rem 1rem;
    font-size: 12px;
  }

  #selected-lang {
    font-size: 12px;
    font-weight: 700;
  }
}

@media screen and (width <= 360px) {
  .header {
    padding: .5rem .75rem;
  }

  .center-logo {
    margin: 0 .25rem;
  }

  .dropdown-arrow {
    width: .6em;
    height: .6em;
  }
}

.apply-content-wrapper, .second-content-wrapper, .first-content-wrapper {
  width: 100%;
  position: relative;
}

#closedContent {
  width: 100%;
  display: none;
  position: relative;
}

.closed-content-wrapper {
  width: 100%;
  position: relative;
}

#closedImage {
  width: 100%;
  height: auto;
  display: block;
}

#closedContent .button-overlay {
  pointer-events: none;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

#closedContent .button-overlay .youtube-container, #closedContent .button-overlay .youtube-container2, #closedContent .button-overlay .action-button {
  pointer-events: auto;
}
/*# sourceMappingURL=index.74873593.css.map */
