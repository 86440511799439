/* Reset and general styles */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: Arial, sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    min-height: 100vh;
    background: black;
}

/* Make sure images don't exceed container width */
img {
    max-width: 100%;
    height: auto;
}

/* Main container */
.header,
.tabs,
.content {
    width: 100%;
    max-width: 720px;
    padding: 0;
    margin: 0;
}

/* Header styles */
.header {
    position: fixed;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    max-width: 45rem;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 1.5rem;
    z-index: 1000;
    background: rgba(21, 24, 27, 0.4);
    backdrop-filter: blur(8px);
}

/* Header styles */
.header {
    position: fixed;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    max-width: 45rem;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: clamp(0.5rem, 2vw, 1rem) clamp(0.75rem, 2vw, 1.5rem);
    z-index: 1000;
    background: rgba(21, 24, 27, 0.4);
    backdrop-filter: blur(8px);
}

/* Left logo section */
.header-left {
    flex: 0 1 20%;
    display: flex;
    align-items: center;
    gap: 10px;
    min-width: 0;
}

.company-logo {
    width: auto;
    height: clamp(16px, 4vw, 32px); /* Responsive height: min 16px, max 32px */
    object-fit: contain;
    cursor: pointer;
}

.back-button {
    width: clamp(16px, 4vw, 32px); /* Responsive width: min 16px, max 32px */
    height: clamp(16px, 4vw, 32px); /* Responsive height: min 16px, max 32px */
    cursor: pointer;
}
/* Center logo section */
.center-logo {
    flex: 0 1 60%;
    text-align: center;
    margin: 0 1rem;
    min-width: 0;
}

.center-logo img {
    width: auto;
    height: clamp(16px, 4vw, 32px); /* Responsive height: min 16px, max 32px */
    object-fit: contain;
}

/* Language select section */
.language-select {
    flex: 0 1 20%;
    position: relative;
    min-width: 0;
    display: flex;
    justify-content: flex-end;
    height: fit-content;
    margin-right: 8px;
}

.language-select .dropdown {
    display: inline-flex;
    color: white;
    max-width: 100%;
}

.language-select .dropdown-select {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 12px;
    font-size: 12px;
    cursor: pointer;
    gap: 0.25rem;
    background: rgba(255, 255, 255, 0.15);
    backdrop-filter: blur(4px);
    border-radius: 100px;
    white-space: nowrap;
    width: fit-content;
}

.language-select .dropdown-content {
    z-index: 1;
    cursor: pointer;
    backdrop-filter: blur(4px);
    white-space: nowrap;
    background: #363333eb;
    border-radius: 12px;
    width: max-content;
    min-width: 100%;
    max-width: calc(100vw - 3rem);
    display: none;
    position: absolute;
    top: calc(100% + 10px);
    left: auto;
    right: 0;
    box-shadow: 0 8px 16px #0003;
}

.language-select .dropdown-option {
    padding: 0.5rem 0.75rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    color: rgba(255, 255, 255, 0.4);
    font-size: 14px;
    font-weight: 500;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.language-select .dropdown-option:hover {
    background: rgba(255, 255, 255, 0.08);
}

/* Add this new rule for selected option */
.language-select .dropdown-option[data-lang='${currentLang}'] {
    color: rgba(255, 255, 255, 1); /* White color for selected language */
    font-weight: 700; /* Optional: make it bold */
}

.language-select .dropdown.active .dropdown-content {
    display: block;
    padding-top: 8px;
    padding-bottom: 8px;
}

.language-select .dropdown.active .dropdown-arrow {
    transform: rotate(180deg);
}

#selected-lang {
    font-weight: 700;
    font-size: 14px;
}

/* Content wrapper */
.content-wrapper {
    width: 100%;
    max-width: 720px;
    /* Calculate based on header height + extra space for safety */
    margin-top: calc(clamp(16px, 4vw, 32px) + clamp(1rem, 4vw, 2rem) + 12px);
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* Tabs styles */
.tabs {
    position: relative;
    line-height: 0;
    max-height: 80px;
}

.tab-image {
    width: 100%;
    height: auto;
    display: block;
}

.tab-button {
    position: absolute;
    top: 0;
    height: 100%;
    width: 50%;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
}

.tab-button-left {
    left: 0;
}

.tab-button-right {
    right: 0;
}

/* Content styles */
.content {
    position: relative;
    line-height: 0;
    font-size: 0;
}

.content-image {
    width: 100%;
    height: auto;
    display: block;
}

.youtube-container {
    position: absolute;
    transform: translate(-50%, -50%);
    aspect-ratio: 16/9;
    transition: all 0.3s ease;
}

.action-button {
    position: absolute;
    padding: 10px 20px;
    background: transparent;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    line-height: 1.5;
    transition: all 0.3s ease;
    /* background-color: red; */
}

/* Hide all content sections by default */
#firstTabContent,
#secondTabContent,
#applyContent {
    display: none;
}

@media screen and (max-width: 480px) {
    .header {
        padding: 0.75rem 1rem;
    }

    .center-logo {
        margin: 0 0.5rem;
    }

    .dropdown-arrow {
        width: 0.7rem;
        height: 0.7rem;
    }
    .language-select .dropdown-option {
        padding: 0.5rem 1rem;
        font-size: 12px;
    }
    #selected-lang {
        font-weight: 700;
        font-size: 12px;
    }
}

@media screen and (max-width: 360px) {
    .header {
        padding: 0.5rem 0.75rem;
    }

    .center-logo {
        margin: 0 0.25rem;
    }

    .dropdown-arrow {
        width: 0.6em;
        height: 0.6em;
    }
}

.apply-content-wrapper {
    position: relative;
    width: 100%;
}

.second-content-wrapper {
    position: relative;
    width: 100%;
}

.first-content-wrapper {
    position: relative;
    width: 100%;
}


#closedContent {
    display: none;
    width: 100%;
    position: relative;
}

.closed-content-wrapper {
    position: relative;
    width: 100%;
}

#closedImage {
    width: 100%;
    height: auto;
    display: block;
}

#closedContent .button-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
}

#closedContent .button-overlay .youtube-container,
#closedContent .button-overlay .youtube-container2,
#closedContent .button-overlay .action-button {
    pointer-events: auto;
}